import React from 'react';

import Modal from '../_layout/_layout';
import * as style from './ModalWant.module.scss';

import imageThanks from '../../../static/images/thanks.png';

const ModalWantThanksEn = ({ modalWantThanksRef }) => {
  return (
    <Modal ref={modalWantThanksRef} modalClass={style.modal_want}>
      <div className={style.modal_content_thanks}>
        <picture>
          <img src={imageThanks} alt="¡Gracias!" width="285" />
        </picture>

        <p>Pronto nos contactaremos contigo</p>

        <button
          className="btn btn--primary auto"
          type="submit"
          onClick={() => modalWantThanksRef.current.closeModal()}
        >
          Continuar
        </button>
      </div>
    </Modal>
  );
};

export default ModalWantThanksEn;
